@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
	.w-content {
		max-width: 64rem /* 1152px */;
		width: calc(100% - 40px);
		margin-left: auto;
		margin-right: auto;

		@media (min-width: 768px) {
			width: calc(100% - 64px);
		}
	}
}

:root {
	--nav: theme("colors.primary");

	@media (prefers-color-scheme: dark) {
		--nav: theme("colors.dark-gray");
	}
}

a {
	@apply text-[color:inherit] font-bold;
}

@font-face {
	font-family: "Just Realize";
	src: url("./fonts/JustRealize.ttf");
}
